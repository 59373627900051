<template>
  <div>
    <CRow>
      <CCol>
  <CCard>
   
  <CCardBody>
   

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
        :responsive="true">
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     <template #actual_staff_m="{item}">
        <td class='font-weight-bold'
          v-if="item.actual_staff_m"
        >{{ item.actual_staff_m }}</td>
        <td v-else>--</td>
      </template>
      <template #start_dt="{item}">
        <td class='font-weight-bold'
          v-if="item.start_dt"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
      <template #end_dt="{item}">
        <td class='font-weight-bold'
          v-if="item.end_dt"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     
      
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
 <CCardFooter align="right">
     

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 
      </CCol>
      </CRow>
 </div>
</template>

<script>
const fields = [
  'index',
 
  { key: "actual_staff_m",label: "Actual Staff Member", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "availability_i",label: "Availability", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "start_dt",label: "Start Date", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "end_dt",label: "End Date", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "createdTime",label: "Created Date & Time", _style: "min-width:200px" },
  //  { key: "show_details",
  //   label: "",
  //   _style: "width:1%",
  //   sorter: false,
  //   filter: false,
  // },
  
];

export default {
  name: "EdrsAvability",
 
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getEDRS() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/edrsDeploymentDetail/"+this.$route.params.ic_n)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   
    
     cancel() {
      this.$router.push({ name: 'Users' })
    },
  },
  mounted() {
    this.getEDRS();
  },
};
</script>